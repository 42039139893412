import { Injectable } from '@angular/core';

@Injectable()
export class Util {
  constructor() {
  }

  SwapArray(array: any, idx: number, direction: string): any {

    if (idx === 0 && direction === 'up') {
      return array;
    }

    if (idx === array.length - 1 && direction === 'down') {
      return array;
    }

    if (direction === 'up') {
      const beforeItem = array.splice(idx - 1, 1)[0];
      if (!this.IsNullOrUndefined(beforeItem)) {
        array.splice(idx, 0, beforeItem);
      }
    } else {
      const afterItem = array.splice(idx + 1, 1)[0];
      if (!this.IsNullOrUndefined(afterItem)) {
        array.splice(idx, 0, afterItem);
      }
    }

    return array;
  }

  IsNullOrUndefined(value: any) {
    return (value === null || value === 'undefined' || value === undefined);
  }

  NewGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

  }
  validaCPF(cpf: string): boolean {
    cpf = cpf.replace('-', '').replace('.', '');
    cpf = cpf.replace('.', '');
    if (cpf === null) {
      return false;
    }
    if (cpf.length !== 11) {
      return false;
    }
    if ((cpf === '00000000000') || (cpf === '11111111111') || (cpf === '22222222222') ||
      (cpf === '33333333333') || (cpf === '44444444444') || (cpf === '55555555555') ||
      (cpf === '66666666666') || (cpf === '77777777777') || (cpf === '88888888888') || (cpf === '99999999999')) {
      return false;
    }
    let numero = 0;
    let caracter = '';
    const numeros = '0123456789';
    let j = 10;
    let somatorio = 0;
    let resto = 0;
    let digito1 = 0;
    let digito2 = 0;
    let cpfAux = '';
    cpfAux = cpf.substring(0, 9);
    for (let i = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) === -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf !== cpfAux) {
      return false;
    }

    return true;

  }

}
