import { Component, OnInit, OnDestroy, ViewChild, HostListener, AfterViewInit, Input, ViewEncapsulation } from '@angular/core';
import { RootLayout } from '../root/root.component';

@Component({
  selector: 'condensed-layout',
  templateUrl: './condensed.component.html',
  styleUrls: ['./condensed.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CondensedComponent extends RootLayout implements OnInit {
  menuLinks = [
    {
      label: "Home",
      routerLink: "/",
      iconType: "letter",
      iconName: "H",
      thumbNailClass: "bg-success"
    },
    //
    {
      label: "Adesão ao Plano",
      iconType: "letter",
      iconName: "AP",
      toggle: "close",
      submenu: [
        {
          label: "Dados Paticipante",
          routerLink: "adesao-plano/dados-participante",
          iconType: "letter",
          iconName: "DP",
        },
        {
          label: "Plano Instituído",
          routerLink: "adesao-plano/plano-instituido",
          iconType: "letter",
          iconName: "PI"
        }
      ]
    },
    //
    {
      label: "Recadastramento",
      iconType: "letter",
      iconName: "RE",
      toggle: "close",
      submenu: [
        {
          label: "Dados Pessoais",
          routerLink: "recadastramento/dados-pessoais",
          iconType: "letter",
          iconName: "DP",
        },
      ]
    },
    //
    {
      label: "Dependentes",
      iconType: "letter",
      iconName: "DE",
      toggle: "close",
      submenu: [
        {
          label: "Dados Dependentes",
          routerLink: "dependentes/dados-dependentes",
          iconType: "letter",
          iconName: "DD",
        },
      ]
    },
    //
    {
      label: "Contribuição",
      iconType: "letter",
      iconName: "DE",
      toggle: "close",
      submenu: [
        {
          label: "Contribuições Esporádicas",
          routerLink: "contribuicoes/contribuicoes-esporadicas",
          iconType: "letter",
          iconName: "CE",
        },
        {
          label: "Porcentual de Contribuição",
          routerLink: "contribuicoes/porcentual-contribuicao",
          iconType: "letter",
          iconName: "PC",
        },
        {
          label: "Extrato de Contribuição",
          routerLink: "contribuicoes/extrato-contribuicao",
          iconType: "letter",
          iconName: "EC",
        },
      ]
    },
    //
    {
      label: "Institutos",
      iconType: "letter",
      iconName: "IN",
      toggle: "close",
      submenu: [
        {
          label: "Solicitação de Resgate",
          routerLink: "institutos/solicitacao-resgate",
          iconType: "letter",
          iconName: "CE",
        },
        {
          label: "Porcentual de Contribuição",
          routerLink: "institutos/solicitacao-portabilidade",
          iconType: "letter",
          iconName: "SP",
        },
        {
          label: "Gerar Boleto de Autopatrocinado",
          routerLink: "institutos/gerar-autopatrocinado",
          iconType: "letter",
          iconName: "GBA",
        },
      ]
    },
    {
      label: "Benefícios",
      iconType: "letter",
      iconName: "BE",
      toggle: "close",
      submenu: [
        {
          label: "Simulador de Benefícios",
          routerLink: "beneficios/simulador-beneficios",
          iconType: "letter",
          iconName: "SB",
        },
        {
          label: "Requerer Benefício",
          routerLink: "beneficios/requerer-beneficio",
          iconType: "letter",
          iconName: "RB",
        },
        {
          label: "Alteração Saque á Vista",
          routerLink: "beneficios/alteracao-saque",
          iconType: "letter",
          iconName: "AS",
        },
        {
          label: "Alteração de Recebimento",
          routerLink: "beneficios/alteracao-recebimento",
          iconType: "letter",
          iconName: "AR",
        },
        {
          label: "Contracheque",
          routerLink: "beneficios/contracheque",
          iconType: "letter",
          iconName: "CC",
        },
      ]
    },
    //
    {
      label: "Empréstimos",
      iconType: "letter",
      iconName: "EM",
      toggle: "close",
      submenu: [
        {
          label: "Solicitação de Empréstimos",
          routerLink: "emprestimos/solicitacao-emprestimos",
          iconType: "letter",
          iconName: "SE",
        },
        {
          label: "Contratos Existentes",
          routerLink: "institutos/contratos",
          iconType: "letter",
          iconName: "CE",
        },
      ]
    },
    //
    {
      label: "Administração Informações",
      routerLink: "adm-info",
      iconType: "letter",
      iconName: "ADM",
    },
    //
    {
      label: "Informe de Rendimentos",
      routerLink: "info-rendimentos",
      iconType: "letter",
      iconName: "IR",
    },
    //
    {
      label: "Dossiê do Participante",
      routerLink: "dossie-participante",
      iconType: "letter",
      iconName: "DP",
    },
    //
    {
      label: "Contatos",
      routerLink: "contatos",
      iconType: "letter",
      iconName: "CTT",
    },
  ];

  ngOnInit() {
  }

}
