import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'views-page',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
