//// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/basf/webapiadm/'
  //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/sebraeprev/webapiadm/'
  //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/valueprev/webapiadm/'
  //TrustCRMApiHost: 'https://tstapi.valueprev.com.br/webapiadm/',
  //TrustCRMApiHost: 'https://tstapi.valueprev.com.br/webapiadm/',
  //TrustCRMApiHost: 'https://familia.previndus.com.br/webapiadm/'
  //TrustCRMApiHost: 'http://familiadev.previndus.com.br/webapiadm/' //homologação previndus 
  //TrustCRMApiHost: 'http://localhost:40320/',
  TrustApiIntegracao:'http://localhost:51593/',
  //TrustCRMApiHost: 'http://integracaoprev-qs.southamerica.basf.net/webapiadm/'  //ambiente basf
  //TrustCRMApiHost: 'https://homologacao.trustprev.com.br/Previndus/webapiadm/',
  TrustCRMApiHost: 'https://api.valueprev.com.br/webapiadm/'
  
};

// cliente previndus
//export const client: string = "previndus";

//client basf
//export const client: string = "basf";

// cliente sebrae
//export const client: string = "sebrae";

// cliente valueprev
export const client: string = "valueprev";

