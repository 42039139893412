import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'image-widget',
  templateUrl: './image-widget.component.html',
  styleUrls: ['./image-widget.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ImageWidgetComponent implements OnInit {

  constructor() { }

  imageStr: string = "";

  ngOnInit() {
  }

  @Input()
  set image(value: string) {
    this.imageStr = value
  }

}

