
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';


@Injectable()
export class QuickviewService {

  constructor(private http: Http) { }

  // Get from the API
  getNotes() {
    return this.http.get('assets/data/notes.json').pipe(
      map(res => res.json()));
  }

  getUsers() {
    return this.http.get('assets/data/users.json').pipe(
      map(res => res.json()));
  }

  getChatMessages() {
    return this.http.get('assets/data/messages.json').pipe(
      map(res => res.json()));
  }
}
