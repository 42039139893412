import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()

export class JwtInterceptor implements HttpInterceptor {

    constructor(){}
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        const currentToken = localStorage.getItem('token');
        if(currentToken){
            request = request.clone({
                setHeaders:{
                    Authorization: `Bearer ${currentToken}`
                }
                
            });
        }
        
    return next.handle(request)

    }

}